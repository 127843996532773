@import "assets/scss/style";


.data-table-paper {
  .MuiListItemIcon-root {
    .MuiSvgIcon-root {
      color: #000 !important;
    }
  }
  .data-table-toolbar,
  .data-table-body,
  .data-table-footer {
    background-color: $primaryGray600;
  }
  .data-table-header {
    .Mui-TableHeadCell-Content-Wrapper {
      white-space: pre !important;
    }
    .Mui-TableHeadCell-Content-Wrapper + span {
      display: none;
    }
  }
  .data-table-header tr {
    background-color: $primarySilver100;
    .MuiTableCell-root {
      background-color: $primarySilver100;
      box-shadow: none;
    }
  }

  .data-table-pagination {
    .MuiTablePagination-root {
      display: flex;
      justify-content: flex-end;
    }

    .MuiTablePagination-toolbar .MuiTablePagination-actions {
      flex-shrink: 0;
      margin: 0 15px;
      gap: 10px;
      display: flex;
    }

    .MuiTablePagination-actions button {
      opacity: 0.5;
      border-radius: 2px;
      padding: 2px;
      background-color: rgba(219, 219, 219, 0.5);
    }


    .MuiTablePagination-selectLabel {
      font-family: $mainFontFamily;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #84818a;
    }


    .MuiBox-root,
    .MuiTableRow-root {
      background-color: $primarySilver100;
    }
  }

  .table-row-actions {
    visibility: hidden;
  }
}


