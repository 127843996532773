@import "../abstracts/mixins";

:root {
  --primary-blue: #303778;
  --primary-blue-500: #262c60;
  --primary-blue-400: #595f93;
  --primary-blue-300: #8387ae;
  --primary-blue-200: #acafc9;
  --primary-blue-100: #d6d7e4;

  --primary-green: #22b59c;
  --primary-green-500: #1b917d;
  --primary-green-400: #4ec4b0;
  --primary-green-300: #7ad3c4;
  --primary-green-200: #a7e1d7;
  --primary-green-100: #d3f0eb;

  --primary-gray: #464646;
  --primary-gray-600: #fbfbfb;
  --primary-gray-500: #383838;
  --primary-gray-400: #6b6b6b;
  --primary-gray-300: #909090;
  --primary-gray-200: #b5b5b5;
  --primary-gray-100: #dadada;
  --primary-gray-50: #929292;
  --primary-shade-gray: #6d6d6d;

  --primary-silver: #c9c9c9;
  --primary-silver-500: #a1a1a1;
  --primary-silver-400: #d4d4d4;
  --primary-silver-300: #dfdfdf;
  --primary-silver-200: #e9e9e9;
  --primary-silver-100: #f4f4f4;

  --lavender: #ebeaed;

  --primary-card: rgba(255, 255, 255, 0.7);
  --secondary-card: rgba(233, 233, 233, 0.2);

  --background-color: #f7f7f7;

  --status-danger: rgba(255, 0, 0, 0.99);
  --status-warning: #f59e0b;
  --status-warning-100: #ff8903;
  --status-warning-200: #f00000;

  --status-success: #19a251;

  --primary-orange: #e13901;
  --primary-orange-500: #c73602;

  --link-color: #0072e3;
  --second-link-color: #2f58cd;

  --primary-body-text-color: #2e2c34;

  --folder-color: #ffa043;
  --light-gray-transparent: #dbdbdb1a;

  --main-font-family: "Poppins", sans-serif;
  --secondary-font-family: "Montserrat", sans-serif;
}

.MuiFormHelperText-root {
  position: absolute;
  top: 83%;
}
.line {
  width: 96.5%;
  position: absolute;
  top: 100%;
  left: 2%;
  background: var(--primary-silver-300);
  height: 1px;
}
