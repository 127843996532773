@import "assets/scss/style";

.filter-badge {
  min-width: 20px;
  min-height: 20px;
  margin: 0 57px 0 8px;
  background-color: rgba($primaryBlue400, 0.2);
  color: $primaryBlue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}