@import "assets/scss/style";


.oval {
  width: 151px;
  height: 151px;
  margin: 0 108px 18px 109px;
  padding: 42px 31px 33px;
  background-color: #fff;
  border-radius: 50%;
}
.zero-template-image {
  width: 89px;
  height: 76px;
  object-fit: contain;
}

.zero-template-title {
  font-family: $secondaryFontFamily;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryGray;
  text-align: center;
  margin-bottom: 1.5rem;
  @include respond(tablet){
    font-size: 38px;
  }
}

.zero-template-description {
  width: 368px;
  opacity: 0.8;
  font-size: 16px;
  @include respond(tablet){
    font-size: 14px;
  }
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $primaryGray;
}