@import "assets/scss/style";

.form-card {
  margin: 5px 0;
  padding: 12px 12px 11px 24px;
  border-radius: 4px;
  border: solid 1px $primarySilver200;
  background-color: rgba(255, 255, 255, 0.52);
  .card-action {
    padding: 2.5px;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    background-color: #fff;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .MuiGrid-root > .MuiGrid-item {
    padding-top: 0;
  }
}