@import "assets/scss/style";


.template-image {
  width: 240px;
  height: 200px;
  object-fit: contain;
}

.template-title {
  width: 330px;
  height: 47px;
  font-family: $secondaryFontFamily;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryGray;
  text-align: center;
  @include respond(tablet){
    width: 477px;
    font-size: 38px;
  }
}

.template-description {
  width: 330px;
  height: 40px;
  opacity: 0.8;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $primaryGray;
  @include respond(tablet){
    width: 429px;
    font-size: 14px;
  }
}