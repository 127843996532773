$primaryBlue: #303778;
$primaryBlue500: #262c60;
$primaryBlue400: #595f93;
$primaryBlue300: #8387ae;
$primaryBlue200: #acafc9;
$primaryBlue100: #d6d7e4;


$primaryGreen: #22B59C;
$primaryGreen500: #1B917D;
$primaryGreen400: #4ec4b0;
$primaryGreen300: #7ad3c4;
$primaryGreen200: #a7e1d7;
$primaryGreen100: #d3f0eb;

$primaryGray: #464646;
$primaryGray600: #FBFBFB;
$primaryGray500: #383838;
$primaryGray400: #6b6b6b;
$primaryGray300: #909090;
$primaryGray200: #b5b5b5;
$primaryGray100: #dadada;
$primaryGray50: #929292;
$primaryShadeGray: #6d6d6d;

$primarySilver: #C9C9C9;
$primarySilver500: #a1a1a1;
$primarySilver400: #d4d4d4;
$primarySilver300: #dfdfdf;
$primarySilver200: #e9e9e9;
$primarySilver100: #f4f4f4;

$lavender: #ebeaed;


$primaryCard: rgba(255, 255, 255, 0.7);
$secondaryCard: rgba(233, 233, 233, 0.2);

$backgroundColor: #F7F7F7;


$statusDanger: rgba(255, 0, 0, 0.99);

$statusWarning: #F59E0B;
$statusWarning100: #ff8903;
$statusWarning200: #f00000;

$statusSuccess: #19A251;

$primaryOrange: #e13901;
$primaryOrange500: #c73602;

$linkColor: #0072E3;
$secondLinkColor: #2f58cd;

$primaryBodyTextColor: #2e2c34;

$folderColor: #ffa043;
$lightGrayTransparent: #DBDBDB1A;

$mainFontFamily: 'Poppins', sans-serif;
$secondaryFontFamily: 'Montserrat', sans-serif;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $backgroundColor;

  // constants
  gridSpacing: 3;
  drawerWidth: 290;
  appDrawerWidth: 320;

  // fonts
  mainFontFamily: $mainFontFamily;
  secondaryFontFamily: $secondaryFontFamily;


  primaryBlue: $primaryBlue;
  primaryBlue500: $primaryBlue500;
  primaryBlue400: $primaryBlue400;
  primaryBlue300: $primaryBlue300;
  primaryBlue200: $primaryBlue200;
  primaryBlue100: $primaryBlue100;


  primaryGreen: $primaryGreen;
  primaryGreen500: $primaryGreen500;
  primaryGreen400: $primaryGreen400;
  primaryGreen300: $primaryGreen300;
  primaryGreen200: $primaryGreen200;
  primaryGreen100: $primaryGreen100;

  primaryGray: $primaryGray;
  primaryGray600: $primaryGray600;
  primaryGray500: $primaryGray500;
  primaryGray400: $primaryGray400;
  primaryGray300: $primaryGray300;
  primaryGray200: $primaryGray200;
  primaryGray100: $primaryGray100;
  primaryGray50: $primaryGray50;

  primarySilver: $primarySilver;
  primarySilver500: $primarySilver500;
  primarySilver400: $primarySilver400;
  primarySilver300: $primarySilver300;
  primarySilver200: $primarySilver200;
  primarySilver100: $primarySilver100;

  secondaryCard: $secondaryCard;
  primaryCard: $primaryCard;

  backgroundColor: $backgroundColor;

  primaryOrange: $primaryOrange;
  primaryOrange500: $primaryOrange500;
  statusDanger: $statusDanger;

  statusWarning: $statusWarning;
  statusWarning100: $statusWarning100;
  statusWarning200: $statusWarning200;

  statusSuccess: $statusSuccess;

  primaryBodyTextColor: $primaryBodyTextColor;

  linkColor: $linkColor;
  secondLinkColor: $secondLinkColor;
  lavender: $lavender;

  folderColor: $folderColor;
  primaryShadeGray: $primaryShadeGray;
  lightGrayTransparent: $lightGrayTransparent;
}