@import "assets/scss/style";


.items-count {
  opacity: 0.5;
  font-size: 15px;
  @include respond(tablet) {
    font-size: 20px;
  }
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryGray;
}