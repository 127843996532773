@import "assets/scss/style";

.card-item-label{
  opacity: 0.7;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryGray;
}

.card-item-description {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryGray;
  .card-item-image {
    height: 42px;
  }
  max-width: 100%;
  word-wrap: break-word;
}

