@import "assets/scss/style";


.loader-image {
  width: 154px;
  height: 78px;
  object-fit: contain;
}

.title {
  width: 95px;
  height: 20px;
  font-family: $mainFontFamily;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

@-webkit-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-moz-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-o-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.loader {
  width: 100%;
  -webkit-animation-duration: 30s;
  -moz-animation-duration: 30s;
  -o-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;
  svg {
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 300px;
  }
  .animation {
    fill: none;
    stroke: #fff;
    stroke-linecap: square;
    stroke-miterlimit: 10;
    stroke-width: 4px;
    opacity: 1;
    stroke-dasharray: 600;
    -webkit-animation: move 3s linear forwards infinite, fade 3s linear infinite;
    animation: move 3s linear forwards infinite, fade 3s linear infinite;
  }
}


