@import "variables";

@mixin respond($breakpoint, $min: 0, $max: 100vw) {
  @if $breakpoint==custom-media {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @if $breakpoint==phone {
    @media (min-width: 425px) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==laptop {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (min-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint==desktop-xl {
    @media (min-width: 1800px) {
      @content;
    }
  }
}